<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :escCancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
    size="md"
  >
    <template #header>
      Edycja uprawnień KNF
    </template>
    <template #default>
      <div class="flex flex-wrap gap-x-3 gap-y-1 text-muted">

        <div class="flex-grow p-3 bg-white dark:bg-gray-800 rounded shadow-xl">
          <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-700">
            <h1 class="uppercase font-semibold">posiadane</h1>
          </div>
          <div class="flex flex-col gap-y-1">
            <div v-for="oInsurer in newOwnedInsurerList" :key="oInsurer.id" class="flex gap-x-2 px-2 bg-gray-100 dark:bg-gray-900 rounded-sm">
              <span class="flex-grow">{{ oInsurer.name }}</span>
              <a href="#" @click.prevent="remove(oInsurer.id)" class="text-red-400 dark:text-red-900 hover:text-red-300 dark:hover:text-red-700" title="usuń"><FontAwesomeIcon icon="times" /></a>
            </div>
          </div>
        </div>

        <div class="flex-grow p-3 bg-white dark:bg-gray-800 rounded shadow-xl">
          <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-700">
            <h1 class="uppercase font-semibold">dostępne</h1>
          </div>
          <div class="flex flex-col gap-y-1">
            <div v-for="aInsurer in availableInsurerList" :key="aInsurer.id" class="flex gap-x-2 pl-1 bg-gray-100 dark:bg-gray-900 rounded-sm">
              <a href="#" @click.prevent="add(aInsurer.id)"  class="text-blue-400 dark:text-blue-900 hover:text-blue-300 dark:hover:text-blue-700" title="dodaj"><FontAwesomeIcon icon="plus" /></a>
              <span>{{ aInsurer.name }}</span>
            </div>
          </div>
        </div>

      </div>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';

export default {
  name: 'EmployeeInsurersEdit',
  mixins: [errorHandler],
  props: {
    employeeId: {
      type: [Number, String],
      required: true,
    },
    employeeInsurers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newOwnedInsurerIds: [],
      saveErrors: {},
      saving: false,
    };
  },
  computed: {
    insurerList() {
      return this.$store.getters['products/insurerList'];
    },
    availableInsurerList() {
      return this.insurerList.filter(el => !this.newOwnedInsurerIds.includes(el.id));
    },
    newOwnedInsurerList() {
      return this.insurerList.filter(el => this.newOwnedInsurerIds.includes(el.id));
    },
    ownedInsurerIds() {
      return this.employeeInsurers.map(el => el.id);
    },
    saveNotAvailable() {
      return JSON.stringify(this.ownedInsurerIds) === JSON.stringify(this.newOwnedInsurerIds);
    },
  },
  mounted() {
    this.newOwnedInsurerIds = this.employeeInsurers.map(el => el.id);
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
  },
  methods: {
    add(id) {
      this.newOwnedInsurerIds.push(id);
      this.newOwnedInsurerIds.sort((a, b) => Number(a) - Number(b));
    },
    remove(id) {
      this.newOwnedInsurerIds = this.newOwnedInsurerIds.filter(el => el !== id);
    },
    save() {
      this.saving = true;
      EmployeeService.updateEmployeeInsurers(this.employeeId, this.newOwnedInsurerIds)
        .then((response) => {
          this.$emit('insurersUpdated', response.data.result);
          this.saving = false;
        })
        .catch((error) => {
          this.resolveError(error);
          this.saving = false;
        });
    },
  },
};
</script>
