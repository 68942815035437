<template>
  <div id="employee-card" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

    <EmployeeCompanyAddEdit v-if="companyModal"
      :action="companyModalAction"
      :employeeId="employee.id"
      :company="employee.company"
      @companyUpdated="companyUpdated"
      @close="companyModal = false"
    />
    <EmployeeEdit
      v-if="editModal"
      :employee="employee"
      @close="editModal = false"
      @employeeUpdated="employeeUpdated"
    />

    <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">karta pracownika</span>
          <FontAwesomeIcon :icon="employeeStatus.icon" :class="employeeStatus.color" size="sm" :title="employeeStatus.description" />
        </div>
      </div>
      <div v-if="validating === 1">
        <CardMenu>
          <template #default>
            <ul class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="editModal = true" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj</li>
              <li  v-if="Number(employee.status_id) < 20" @click="changeStatusHandler(20)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-yellow-400 dark:text-yellow-600" :icon="['fas', 'user-lock']" /> zablokuj
              </li>
              <li v-if="Number(employee.status_id) === 20" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-lock']" /> odblokuj
              </li>
              <li v-if="Number(employee.status_id) < 30" @click="changeStatusHandler(30)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-red-400 dark:text-red-600" :icon="['fas', 'user-times']" /> wyłącz
              </li>
              <li v-if="Number(employee.status_id) === 30" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-times']" /> włącz
              </li>
              <li v-if="Number(employee.status_id) !== 40" @click="changeStatusHandler(40)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-red-400 dark:text-red-600" :icon="['fas', 'user-slash']" /> usuń
              </li>
              <li v-if="Number(employee.status_id) === 40" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-slash']" /> przywróć
              </li>
            </ul>
          </template>
        </CardMenu>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div id="employment-details" class="border-b dark:border-gray-600">
      <div class="flex flex-wrap justify-between">
        <div class="flex gap-x-3"><span class="text-muted">stanowisko</span>{{ employee.empl_type_name }}</div>
        <div class="flex gap-x-3"><span class="text-muted">forma zatrudnienia</span>{{ employee.empl_form_name }}</div>
        <div class="flex gap-x-3"><span class="text-muted">poziom prowizji</span>{{ employee.provision_lvl_name }}</div>
      </div>
      <div class="flex flex-wrap justify-between">
        <div class="flex gap-x-3"><span class="text-muted">zarządza agentami</span>{{ Number(employee.is_manager) === 0 ? 'nie' : 'tak' }}</div>
        <div v-if="employee.manager_id !== ''" class="flex gap-x-3">
          <span class="text-muted">bezpośredni przełożony</span>{{ employee.mng_forename }} {{ employee.mng_surname }}
        </div>
      </div>
    </div>

    <div id="personal-data" class="flex flex-wrap justify-between mt-1">
      <div class="flex gap-1">
        <span class="text-bright">{{ employee.person.surname }}</span>
        <span class="text-bright">{{ employee.person.forename1 }}</span>
        <span class="text-bright">{{ employee.person.forename2 }}</span>
      </div>
      <div class="flex gap-2">
        <span class="text-muted">{{ Number(employee.person.foreigner) === 0 ? 'pesel' : 'nr paszportu' }}</span><span class="text-bright">{{ employee.person.pesel }}</span>
      </div>
      <div class="flex justify-between w-full text-base">
        <div class="flex gap-3">
          <span class="text-muted">data ur.</span>
          <span v-if="employee.person.birth_date_h" class="text-bright">{{ employee.person.birth_date_h }}</span>
          <span v-else class="italic">nie podano</span>
        </div>
        <div v-if="employee.person.nationality || Number(employee.person.foreigner) === 1" class="flex gap-3">
          <span class="text-muted">narodowość</span>
          <span v-if="employee.person.nationality" class="text-bright">{{ employee.person.nationality }}</span>
          <span v-else class="italic">nie podano</span>
        </div>
      </div>
    </div>

    <div id="address-data">
      <div id="employee-main-address" class="flex gap-3 w-full">
        <span class="text-muted">adres główny</span>
        <div v-if="mapsMainAddressUrl" class="flex flex-wrap items-center gap-2">
          <span class="text-bright font-normal"><em>{{ employee.person.street }} {{ employee.person.house_ap_no }}</em></span>
          <span class="text-bright">{{ employee.person.postal_code }} {{ employee.person.city }}</span>
          <span v-show="employee.person.region" class="italic">{{ employee.person.region }}</span>
          <span v-show="employee.person.country" class="font-semibold">{{ employee.person.country }}</span>
          <a @click.stop :href="mapsMainAddressUrl" target="_blank">
            <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
          </a>
        </div>
        <div v-else class="text-muted text-sm"><em>nie podano adresu</em></div>
      </div>
      <div id="employee-ext-address" v-if="mapsExtAddressUrl" class="flex gap-3 w-full">
        <span class="text-muted">adres koresp.</span>
        <div class="flex flex-wrap items-center gap-2">
          <span class="text-bright font-normal"><em>{{ employee.person.c_street }}</em></span>
          <span class="text-bright">{{ employee.person.c_postal_code }} {{ employee.person.c_city }}</span>
          <span v-show="employee.person.c_region" class="italic">{{ employee.person.c_region }}</span>
          <span v-show="employee.person.c_country" class="font-semibold">{{ employee.person.c_country }}</span>
          <a @click.stop :href="mapsExtAddressUrl" target="_blank">
            <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
          </a>
        </div>
      </div>
    </div>

    <div id="employee-phones-emails" class="flex flex-wrap gap-2 mt-3">
      <EmailsPhones
        ownerType="employee"
        :ownerId="employee.id"
        type="phones"
        :phones="employee.phones"
        @added="phoneAdded"
        @updated="phoneUpdated"
        @deleted="phoneDeleted"
      />
      <EmailsPhones
        ownerType="employee"
        :ownerId="employee.id"
        type="emails"
        :emails="employee.emails"
        @added="emailAdded"
        @updated="emailUpdated"
        @deleted="emailDeleted"
      />
    </div>

    <PersonConsents class="mt-3"
      :consents="employee.consents"
      :personId="employee.personal_data_id"
      :allowEdit="gdprEditPermission"
      :alert="false"
      @consentAdded="consentAdded"
      @consentUpdated="consentUpdated"
      @consentDeleted="consentDeleted"
    />

    <div id="employee-company" class="flex flex-col mt-2">
      <div id="employee-company-data-header" class="flex items-end justify-between text-muted border-b dark:border-gray-600">
        <div class="text-sm font-semibold uppercase">Dane do faktury</div>
        <button v-if="employee.company.id === undefined" @click="addCompanyData" class="tw--btn-icon pb-0" title="dodaj dane do faktury">
          <FontAwesomeIcon icon="plus" /> <FontAwesomeIcon :icon="['far', 'file-alt']" size="lg" class="-ml-1" />
        </button>
        <CardMenu v-else>
          <template #default>
            <ul class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="editCompanyData" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj</li>
              <li @click="delCompanyDataHandler" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-red-400 dark:text-red-600" :icon="['far', 'trash-alt']" /> usuń</li>
            </ul>
          </template>
        </CardMenu>
      </div>

      <div v-if="employee.company.id !== undefined" id="employee-company-data">
        <div class="flex flex-wrap justify-between">
          <div class="flex gap-3"><span class="text-muted">nazwa</span>{{ employee.company.name }}</div>
          <div class="flex gap-3">
            <span class="text-muted">NIP</span>
            <span v-if="employee.company.nip">{{ employee.company.nip }}</span>
            <span v-else class="italic">nie podano</span>
          </div>

          <div id="employee-company-address" class="flex gap-3 w-full">
            <span class="text-muted">adres</span>
            <div v-if="employee.company.postal_code" class="flex flex-wrap items-center gap-2">
              <span class="text-bright font-normal"><em>{{ employee.company.street }}</em></span>
              <span class="text-bright">{{ employee.company.postal_code }} {{ employee.company.city }}</span>
              <span v-show="employee.company.region" class="italic">{{ employee.company.region }}</span>
              <span v-show="employee.company.country" class="font-semibold">{{ employee.company.country }}</span>
              <a v-if="mapsCompanyAddressUrl" @click.stop :href="mapsCompanyAddressUrl" target="_blank">
                <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
              </a>
            </div>
            <div v-else class="text-muted text-sm"><em>nie podano adresu</em></div>
          </div>
        </div>
      </div>
      <div v-else class="text-muted italic text-sm">nie podano</div>

    </div>

    <div id="employee-bank" class="flex gap-3">
      <span class="text-muted">Konto bankowe</span>
      <div v-if="Number(employee.is_company) === 1 && employee.company.bank_acc_no !== ''" class="flex gap-x-5">
        <span>{{ employee.company.bank }}</span><span class="text-bright">{{ employee.company.bank_acc_no | bankAcc }}</span>
      </div>
      <div v-else-if="Number(employee.is_company) === 0 && employee.person.bank_acc_no !== ''" class="flex gap-x-2">
        <span>{{ employee.person.bank }}</span><span class="text-bright">{{ employee.person.bank_acc_no | bankAcc }}</span>
      </div>
      <div v-else class="italic">nie podano</div>
    </div>

  </div>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';
import EmailsPhones from '../emailsPhones/EmailsPhones.vue';
import PersonConsents from '../gdpr/PersonConsents.vue';
import EmployeeCompanyAddEdit from './EmployeeCompanyAddEdit.vue';
import EmployeeEdit from './EmployeeEdit.vue';

export default {
  name: 'EmployeeCard',
  components: {
    EmailsPhones,
    PersonConsents,
    EmployeeCompanyAddEdit,
    EmployeeEdit,
  },
  mixins: [confirm, errorHandler],
  props: {
    employee: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      editModal: false,
      companyModal: false,
      companyModalAction: 'add',
    };
  },
  computed: {
    validating() {// -1 błąd, 0 łąduję dane, 1 ok = MenuCard
      return this.employee.id !== undefined ? 1 : 0;
    },
    gdprEditPermission() {
      return this.permissions.gdpr_consent_edit_rights === 1 || this.permissions.gdpr_consent_edit_rights === '1';
    },
    employeeStatus() {
      if (Number(this.employee.status_id) === 40) {
        return { icon: 'user-slash', color: 'text-red-400 dark:text-red-600', description: 'usunięty' };
      } else if (Number(this.employee.status_id) === 30) {
        return { icon: 'user-times', color: 'text-red-400 dark:text-red-600', description: 'wyłączony' };
      } else if (Number(this.employee.status_id) === 20) {
        return { icon: 'user-lock', color: 'text-yellow-400 dark:text-yellow-600', description: 'zablokowany' };
      } else if (Number(this.employee.status_id) === 10) {
        return { icon: 'user-clock', color: 'text-yellow-400 dark:text-yellow-600', description: 'nieaktywny' };
      }
      return { icon: 'user-check', color: 'text-green-400 dark:text-green-600', description: 'aktywny' };
    },
    mapsMainAddressUrl() {
      let url = 'https://maps.google.com/?q=';
      let params = '';
      if (this.employee.person.street) {
        params = `${params}${this.employee.person.street} ${this.employee.person.house_ap_no}`;
      }
      if (this.employee.person.postal_code) {
        params = `${params}+${this.employee.person.postal_code}`;
      }
      if (this.employee.person.city) {
        params = `${params}+${this.employee.person.city}`;
      }
      if (params.length === 0) {
        return false;
      }
      return `${url}${params}`;
    },
    mapsExtAddressUrl() {
      let url = 'https://maps.google.com/?q=';
      let params = '';
      if (this.employee.person.c_street) {
        params = `${params}${this.employee.person.c_street} ${this.employee.person.c_house_ap_no}`;
      }
      if (this.employee.person.c_postal_code) {
        params = `${params}+${this.employee.person.c_postal_code}`;
      }
      if (this.employee.person.c_city) {
        params = `${params}+${this.employee.person.c_city}`;
      }
      if (params.length === 0) {
        return false;
      }
      return `${url}${params}`;
    },
    mapsCompanyAddressUrl() {
      let url = 'https://maps.google.com/?q=';
      let params = '';
      if (this.employee.company.street) {
        params = `${params}${this.employee.company.street} ${this.employee.company.house_ap_no}`;
      }
      if (this.employee.company.postal_code) {
        params = `${params}+${this.employee.company.postal_code}`;
      }
      if (this.employee.company.city) {
        params = `${params}+${this.employee.company.city}`;
      }
      if (params.length === 0) {
        return false;
      }
      return `${url}${params}`;
    },
  },
  methods: {
    changeStatusHandler(id) {
      let message = '';
      switch (Number(id)) {
        case 1:
          message = 'Potwierdź ponowną aktywację pracownika';
          break;
        case 20:
          message = 'Potwierdź zablokowanie pracownika. Podczas logowania użytkownik otrzyma informację, że konto zostało zablokowane.';
          break;
        case 30:
          message = 'Potwierdź wyłączenie konta pracownika. Podczas logowania uzytkownik otrzyma informację, że konto nie istnieje w aplikacji.';
          break;
        case 40:
          message = 'Potwierdź usunięcie pracownika.';
          break;
      }
      this.confirm(() => this.changeStatus(id), message);
    },
    changeStatus(id) {
      EmployeeService.updateEmployee(this.employee.id, { status_id: id})
        .then(() => {
          this.$emit('employeeUpdated');
        })
        .catch(error => this.resolveError(error));
    },
    addCompanyData() {
      this.companyModalAction = 'add';
      this.companyModal = true;
    },
    editCompanyData() {
      this.companyModalAction = 'edit';
      this.companyModal = true;
    },
    delCompanyDataHandler() {
      this.confirm(() => this.delCompanyData(), 'Potwierdź usunięcie danych do faktury');
    },
    delCompanyData() {
      EmployeeService.delEmployeeCompany(this.employee.id)
        .then(() => {
          this.$emit('employeeUpdated');
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Dane do faktury zostały usunięte.' });
        })
        .catch(error => this.resolveError(error));
    },
    companyUpdated() {
      this.$emit('employeeUpdated');
      this.companyModal = false;
    },
    employeeUpdated() {
      this.$emit('employeeUpdated');
      this.editModal = false;
    },
    consentAdded(consent) {
      this.$emit('consentAdded', consent);
    },
    consentUpdated(consent) {
      this.$emit('consentUpdated', consent);
    },
    consentDeleted(id) {
      this.$emit('consentDeleted', id);
    },
    phoneAdded(item) {
      this.$emit('phoneAdded', item);
    },
    emailAdded(item) {
      this.$emit('emailAdded', item);
    },
    phoneUpdated(item) {
      this.$emit('phoneUpdated', item);
    },
    emailUpdated(item) {
      this.$emit('emailUpdated', item);
    },
    phoneDeleted(id) {
      this.$emit('phoneDeleted', id);
    },
    emailDeleted(id) {
      this.$emit('emailDeleted', id);
    },
  },
};
</script>
