<template>
  <div class="flex flex-col gap-4">
    <ShortSalseSummary :summary="salesSummary" class="order-last lg:order-first" />
    <div class="flex flex-col lg:flex-row gap-4">
      <EmployeeCard v-if="employee.id !== undefined"
        class="w-full lg:w-1/2"
        :employee="employee"
        :permissions="permissions"
        @employeeUpdated="employeeUpdated"
        @phoneAdded="employeePhoneAdded"
        @phoneUpdated="employeePhoneUpdated"
        @phoneDeleted="employeePhoneDeleted"
        @emailAdded="employeeEmailAdded"
        @emailUpdated="employeeEmailUpdated"
        @emailDeleted="employeeEmailDeleted"
        @consentAdded="consentAdded"
        @consentUpdated="consentUpdated"
        @consentDeleted="consentDeleted"
      />
      <div class="flex flex-col gap-y-4 w-full lg:w-1/2">

        <UserCard v-if="employee.id !== undefined"
          class="w-full"
          :user="user"
          :personId="employee.person.id"
          :employeeEmail="employeeFirstEmail"
          @userAdded="userUpdated"
          @userUpdated="userUpdated"
        />

        <EmployeeInsurers v-if="employee.id !== undefined" class="w-full" :employeeId="employee.id" />

        <NotesCard
          class="w-full flex-grow"
          :notes="notes"
          parent="employee"
          :parentId="employee.id"
          @noteUpdated="noteUpdated"
          @noteDeleted="noteDeleted"
          @noteAdded="noteAdded"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../mixins/errorHandler';
import EmployeeCard from '../components/employee/EmployeeCard.vue';
import NotesCard from '../components/notes/NotesCard.vue';
import UserCard from '../components/user/UserCard.vue';
import EmployeeInsurers from '../components/employee/EmployeeInsurers.vue';
import EmployeeService from '../services/EmployeeService';
import ShortSalseSummary from '../components/ShortSalesSummary.vue';

export default {
  name: 'Employee',
  mixins: [errorHandler],
  components: {
    EmployeeCard,
    NotesCard,
    UserCard,
    ShortSalseSummary,
    EmployeeInsurers,
  },
  data() {
    return {
      permissions: {},
      employee: {},
      notes: [],
      user: {},
      salesSummary: {},
    };
  },
  computed: {
    employeeFirstEmail() {
      // return this.employee.emails !== undefined && this.employee.emails.lenght > 0 ? this.employee.emails[0].value : '';
      return this.employee.emails[0] !== undefined ? this.employee.emails[0].value : '';
    },
  },
  mounted() {
    this.getEmployee();
  },
  methods: {
    getEmployee() {
      EmployeeService.getEmployeeFull(this.$route.params.id)
        .then(response => {
          this.employee = response.data.employee;
          this.notes = response.data.notes;
          this.user = response.data.user;
          this.permissions = response.data.allow;
          EmployeeService.getEmployeeReport(this.employee.id)
            .then(response => {
              this.salesSummary = response.data.report;
            })
            .catch(error => this.resolveError(error));
        })
        .catch(error => this.resolveError(error));
    },
    employeeUpdated() {
      this.getEmployee();
    },
    employeePhoneAdded(item) {
      this.employee.phones.push(item);
    },
    employeePhoneUpdated(item) {
      const updated = this.employee.phones.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    employeePhoneDeleted(id) {
      this.employee.phones = this.employee.phones.filter(obj => Number(obj.id) !== Number(id));
    },
    employeeEmailAdded(item) {
      this.employee.emails.push(item);
    },
    employeeEmailUpdated(item) {
      const updated = this.employee.emails.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    employeeEmailDeleted(id) {
      this.employee.emails = this.employee.emails.filter(obj => Number(obj.id) !== Number(id));
    },
    consentAdded(consent) {
      this.employee.consents.unshift(consent);
    },
    consentUpdated(consent) {
      const consentToUpdate = this.employee.consents.find(obj => Number(obj.id) === Number(consent.id));
      Object.assign(consentToUpdate, consent);
    },
    consentDeleted(id) {
      this.employee.consents = this.employee.consents.filter(obj => Number(obj.id) !== Number(id));
    },
    noteUpdated(note) {
      const updated = this.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(updated, note);
    },
    noteDeleted(id) {
      this.notes = this.notes.filter(note => note.id !== id);
    },
    noteAdded(note) {
      this.notes.unshift(note);
    },
    userUpdated(user) {
      this.user = {};
      Object.assign(this.user, user);
      if (this.employee.user_id === '') {
        this.employee.user_id = user.id;
      }
    },
  },
};
</script>
