<template>
  <div id="employee-form" class="flex flex-col gap-1">
    <div id="empl-type-pref-cont-form" class="flex flex-wrap gap-4">
      <BaseSelectWithAddon
        class="w-full md:w-1/2 lg:w-full xl:w-1/2"
        v-model="employeeForm.empl_type_id"
        labelTop="stanowisko"
        :nullOption="false"
        :options="emplTypeList"
        :error="validationErrors.errors.empl_type_id !== undefined ? validationErrors.errors.empl_type_id : ''"
        :warning="validationErrors.warnings.empl_type_id !== undefined ? validationErrors.warnings.empl_type_id : ''"
      />
      <BaseSelectWithAddon
        class="flex-grow"
        v-model="employeeForm.empl_form_id"
        labelTop="forma zatrudnienia"
        :nullOption="false"
        :options="emplFormList"
        :error="validationErrors.errors.empl_form_id !== undefined ? validationErrors.errors.empl_form_id : ''"
        :warning="validationErrors.warnings.empl_form_id !== undefined ? validationErrors.warnings.empl_form_id : ''"
      />
    </div>
    <div id="empl-type-and-is-manager" class="flex flex-wrap gap-4">
      <BaseSelectWithAddon
        class="w-full md:w-1/2 lg:w-full xl:w-1/2"
        v-model="employeeForm.provision_lvl"
        labelTop="system prowizyjny"
        :nullOption="false"
        :options="emplProvLvlList"
        :error="validationErrors.errors.provision_lvl !== undefined ? validationErrors.errors.provision_lvl : ''"
        :warning="validationErrors.warnings.provision_lvl !== undefined ? validationErrors.warnings.provision_lvl : ''"
      />
      <BaseSelectWithAddon
        class="flex-grow"
        v-model="employeeForm.is_manager"
        labelTop="zarządza agentami"
        :nullOption="false"
        :options="[{ id: '0', name: 'nie'}, { id: '1', name: 'tak'}]"
        :error="validationErrors.errors.is_manager !== undefined ? validationErrors.errors.is_manager : ''"
        :warning="validationErrors.warnings.is_manager !== undefined ? validationErrors.warnings.is_manager : ''"
      />
    </div>
    <BaseSelectWithAddon
      class="w-full md:w-1/2 lg:w-full xl:w-1/2"
      v-model="employeeForm.manager_id"
      labelTop="bezpośredni przełożony"
      :options="emplManagerList"
      :error="validationErrors.errors.manager_id !== undefined ? validationErrors.errors.manager_id : ''"
      :warning="validationErrors.warnings.manager_id !== undefined ? validationErrors.warnings.manager_id : ''"
    />

    <div v-if="edit" id="empl-edit-phone-email" class="flex flex-wrap gap-4 mt-2">
      <span class="text-xs text-muted italic">* możliwość edycji numerów telefonów i adresów email znajduje się bezpośrednio w karcie pracownika</span>
    </div>
    <div v-else id="empl-phone-email" class="flex flex-wrap gap-4 mt-2">
      <BaseInputWithAddon
        class="w-full md:w-1/3 lg:w-full xl:w-1/3"
        type="text"
        v-model="employeeForm.phone"
        labelIconAddon="phone-alt"
        :error="validationErrors.errors.phone !== undefined ? validationErrors.errors.phone : ''"
        :warning="validationErrors.warnings.phone !== undefined ? validationErrors.warnings.phone : ''"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="email"
        v-model="employeeForm.email"
        labelIconAddon="at"
        :error="validationErrors.errors.email !== undefined ? validationErrors.errors.email : ''"
        :warning="validationErrors.warnings.email !== undefined ? validationErrors.warnings.email : ''"
      />
    </div>
    <!-- <hr class="dark:border-gray-600"> -->
    <hr class="dark:border-gray-600 mt-1">
    <div id="surname-forename" class="flex flex-wrap gap-4">
      <BaseInputWithAddon
        class="w-full lg:w-full"
        :class="edit ? 'md:w-2/5 xl:w-2/5' : 'md:w-3/5 xl:w-3/5'"
        type="text"
        v-model="employeeForm.surname"
        labelTop="nazwisko"
        :error="validationErrors.errors.surname !== undefined ? validationErrors.errors.surname : ''"
        :warning="validationErrors.warnings.surname !== undefined ? validationErrors.warnings.surname : ''"
      />
        <!-- class="w-full md:w-auto md:flex-grow lg:w-full" -->
      <BaseAutocompleteInput
        class="flex-grow"
        v-model="employeeForm.forename1"
        labelTop="imię"
        :searchUrl="searchForenameUrl"
        :error="validationErrors.errors.forename1 !== undefined ? validationErrors.errors.forename1 : ''"
        :warning="validationErrors.warnings.forename1 !== undefined ? validationErrors.warnings.forename1 : ''"
      />
      <BaseAutocompleteInput v-if="edit"
        class="flex-grow"
        v-model="employeeForm.forename2"
        labelTop="imię drugie"
        :searchUrl="searchForenameUrl"
        :error="validationErrors.errors.forename2 !== undefined ? validationErrors.errors.forename2 : ''"
        :warning="validationErrors.warnings.forename2 !== undefined ? validationErrors.warnings.forename2 : ''"
      />
    </div>
    <div id="pesel-nationality" class="flex flex-wrap gap-4">
      <BaseInputWithAddon
        class="w-full sm:w-2/5"
        type="text"
        v-model="employeeForm.pesel"
        :labelTop="employeeForm.foreigner === '0' ? 'pesel' : 'paszport'"
        :error="validationErrors.errors.pesel !== undefined ? validationErrors.errors.pesel : ''"
        :warning="validationErrors.warnings.pesel !== undefined ? validationErrors.warnings.pesel : ''"
      />
      <BaseInputWithAddon
        v-if="edit"
        class="flex-grow"
        type="date"
        v-model="employeeForm.birth_date_h"
        labelTop="data urodzenia"
        :error="validationErrors.errors.birth_date_h !== undefined ? validationErrors.errors.birth_date_h : ''"
        :warning="validationErrors.warnings.birth_date_h !== undefined ? validationErrors.warnings.birth_date_h : ''"
      />
      <div class="flex flex-col">
        <div class="text-sm text-transparent">Obcokrajowiec</div>
        <label for="foreigner">
          <input
            id="foreigner"
            type="checkbox"
            true-value="1" false-value="0"
            v-model="employeeForm.foreigner"
            @change="employeeForm.nationality = ''"
          > obcokrajowiec
        </label>
      </div>
      <BaseAutocompleteInput
        :class="edit ? 'w-full sm:w-1/2' : 'flex-grow'"
        v-model="employeeForm.nationality"
        labelTop="narodowość"
        :searchUrl="searchCountryUrl"
        :error="validationErrors.errors.nationality !== undefined ? validationErrors.errors.nationality : ''"
        :warning="validationErrors.warnings.nationality !== undefined ? validationErrors.warnings.nationality : ''"
        :disabled="(employee.id !== undefined && !edit) || employeeForm.foreigner === '0'"
      />
    </div>
    <div class="text-sm text-muted border-b dark:border-gray-600">adres</div>
    <div id="employee-main-address-street" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        v-model="employeeForm.street"
        labelTop="ulica"
        :searchUrl="searchStreetUrl"
        :error="validationErrors.errors.street !== undefined ? validationErrors.errors.street : ''"
        :warning="validationErrors.warnings.street !== undefined ? validationErrors.warnings.street : ''"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="employeeForm.house_ap_no"
        labelTop="nr domu/mieszkania"
        :error="validationErrors.errors.house_ap_no !== undefined ? validationErrors.errors.house_ap_no : ''"
        :warning="validationErrors.warnings.house_ap_no !== undefined ? validationErrors.warnings.house_ap_no : ''"
      />
    </div>
    <div id="employee-main-address-city" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="flex-grow"
        @selected="setCity"
        v-model="employeeForm.postal_code"
        labelTop="kod pocztowy"
        :searchUrl="searchPostalCodeCityUrl"
        :error="validationErrors.errors.postal_code !== undefined ? validationErrors.errors.postal_code : ''"
        :warning="validationErrors.warnings.postal_code !== undefined ? validationErrors.warnings.postal_code : ''"
      />
      <BaseAutocompleteInput
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        v-model="employeeForm.city"
        labelTop="miasto"
        :searchUrl="searchCityUrl"
        :error="validationErrors.errors.city !== undefined ? validationErrors.errors.city : ''"
        :warning="validationErrors.warnings.city !== undefined ? validationErrors.warnings.city : ''"
      />
    </div>
    <div id="employee-main-address-region-country" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="w-full md:w-1/2 lg:w-full xl:w-1/2"
        v-model="employeeForm.region"
        labelTop="województwo"
        :searchUrl="searchRegionUrl"
        :error="validationErrors.errors.region !== undefined ? validationErrors.errors.region : ''"
        :warning="validationErrors.warnings.region !== undefined ? validationErrors.warnings.region : ''"
      />
      <BaseAutocompleteInput
        class="flex-grow"
        v-model="employeeForm.country"
        labelTop="państwo"
        :searchUrl="searchCountryUrl"
        :error="validationErrors.errors.country !== undefined ? validationErrors.errors.country : ''"
        :warning="validationErrors.warnings.country !== undefined ? validationErrors.warnings.country : ''"
      />
    </div>
    <a v-if="edit && !showAddressForCorrespondence" href="#" @click.prevent="addAddrForCorresp = true" class="text-sm tw--link underline">
      dodaj adres korespondencyjny
    </a>
    <div v-if="showAddressForCorrespondence">
      <div class="text-sm text-muted border-b dark:border-gray-600">adres korespondencyjny <a href="#" class="tw--link underline" @click.prevent="delAddrForCorresp">usuń</a></div>
      <div id="employee-ext-address-street" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="w-full md:w-2/3 lg:w-full xl:w-2/3"
          v-model="employeeForm.c_street"
          labelTop="ulica"
          :searchUrl="searchStreetUrl"
          :error="validationErrors.errors.c_street !== undefined ? validationErrors.errors.c_street : ''"
          :warning="validationErrors.warnings.c_street !== undefined ? validationErrors.warnings.c_street : ''"
        />
        <BaseInputWithAddon
          class="flex-grow"
          type="text"
          v-model="employeeForm.c_house_ap_no"
          labelTop="nr domu/mieszkania"
          :error="validationErrors.errors.c_house_ap_no !== undefined ? validationErrors.errors.c_house_ap_no : ''"
          :warning="validationErrors.warnings.c_house_ap_no !== undefined ? validationErrors.warnings.c_house_ap_no : ''"
        />
      </div>
      <div id="employee-ext-address-city" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="flex-grow"
          @selected="setCCity"
          v-model="employeeForm.c_postal_code"
          labelTop="kod pocztowy"
          :searchUrl="searchPostalCodeCityUrl"
          :error="validationErrors.errors.c_postal_code !== undefined ? validationErrors.errors.c_postal_code : ''"
          :warning="validationErrors.warnings.c_postal_code !== undefined ? validationErrors.warnings.c_postal_code : ''"
        />
        <BaseAutocompleteInput
          class="w-full md:w-2/3 lg:w-full xl:w-2/3"
          v-model="employeeForm.c_city"
          labelTop="miasto"
          :searchUrl="searchCityUrl"
          :error="validationErrors.errors.c_city !== undefined ? validationErrors.errors.c_city : ''"
          :warning="validationErrors.warnings.c_city !== undefined ? validationErrors.warnings.c_city : ''"
        />
      </div>
      <div id="employee-ext-address-region-country" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="w-full md:w-1/2 lg:w-full xl:w-1/2"
          v-model="employeeForm.c_region"
          labelTop="województwo"
          :searchUrl="searchRegionUrl"
          :error="validationErrors.errors.c_region !== undefined ? validationErrors.errors.c_region : ''"
          :warning="validationErrors.warnings.c_region !== undefined ? validationErrors.warnings.c_region : ''"
        />
        <BaseAutocompleteInput
          class="flex-grow"
          v-model="employeeForm.c_country"
          labelTop="państwo"
          :searchUrl="searchCountryUrl"
          :error="validationErrors.errors.c_country !== undefined ? validationErrors.errors.c_country : ''"
          :warning="validationErrors.warnings.c_country !== undefined ? validationErrors.warnings.c_country : ''"
        />
      </div>
    </div> <!-- Koniec Adres korespondencyjny -->
    <div class="w-full text-xs italic border-t dark:border-gray-600">* konto bank - tylko, jeśli nie będą uzupełnione osobno dane do faktury</div>
    <div id="employee-bank" class="flex flex-wrap gap-4">
      <BaseInputWithAddon
        class="w-full sm:w-1/4"
        type="text"
        v-model="employeeForm.bank"
        labelTop="bank"
        :error="validationErrors.errors.bank !== undefined ? validationErrors.errors.bank : ''"
        :warning="validationErrors.warnings.bank !== undefined ? validationErrors.warnings.bank : ''"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="employeeForm.bank_acc_no"
        labelTop="nr konta"
        :error="validationErrors.errors.bank_acc_no !== undefined ? validationErrors.errors.bank_acc_no : ''"
        :warning="validationErrors.warnings.bank_acc_no !== undefined ? validationErrors.warnings.bank_acc_no : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'EmployeeForm',
  mixins: [errorHandler],
  props: {
    employee: {
      default() {
        return {};
      },
    },
    validationErrors: {
      type: [Object, Array],
      default() {
        return { errors: {}, warnings: {} };
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employeeForm: {},
      addAddrForCorresp: false,
      searchForenameUrl: 'search-forename',
      searchStreetUrl: 'search-street',
      searchPostalCodeCityUrl: 'search-postal-code-city',
      searchCityUrl: 'search-city',
      searchCountryUrl: 'search-country',
      searchRegionUrl: 'search-region',
    };
  },
  computed: {
    ...mapState('employees', [
      'emplTypeList',
      'emplFormList',
      'emplManagerList',
      'emplProvLvlList',
    ]),
    showAddressForCorrespondence() {
      const dirty = this.employeeForm.c_street || this.employeeForm.c_house_ap_no || this.employeeForm.c_postal_code || this.employeeForm.c_city || this.employeeForm.c_region || this.employeeForm.c_country;
      return !!dirty || this.addAddrForCorresp;
    },
  },
  created() {
    this.resetEmployee();
    if (this.edit) {
      Object.keys(this.employeeForm).forEach(key => { this.employeeForm[key] = this.employee[key] !== undefined ? `${this.employee[key]}` : ''; });
    }
  },
  mounted() {
    if (this.emplTypeList.length === 0) this.$store.dispatch('employees/getEmplTypeList').catch(error => this.resolveError(error));
    if (this.emplFormList.length === 0) this.$store.dispatch('employees/getEmplFormList').catch(error => this.resolveError(error));
    if (this.emplManagerList.length === 0) this.$store.dispatch('employees/getManagerList').catch(error => this.resolveError(error));
    if (this.emplProvLvlList.length === 0) this.$store.dispatch('employees/getEmplProvList').catch(error => this.resolveError(error));
  },
  methods: {
    setCity(selectedSuggestion) {
      this.employeeForm.city = selectedSuggestion.name;
    },
    setCCity(selectedSuggestion) {
      this.employeeForm.c_city = selectedSuggestion.name;
    },
    emitFormChanged: debounce(function() {
      this.$emit('formChanged', this.employeeForm);
    }, 500),
    delAddrForCorresp() {
      this.employeeForm.c_street = '';
      this.employeeForm.c_house_ap_no = '';
      this.employeeForm.c_postal_code = '';
      this.employeeForm.c_city = '';
      this.employeeForm.c_region = '';
      this.employeeForm.c_country = '';
      this.addAddrForCorresp = false;
    },
    resetEmployee() {
      this.employeeForm = {
        empl_type_id: '',
        empl_form_id: '',
        provision_lvl: '',
        is_manager: '0',
        manager_id: '',
        phone: '',
        email: '',
        surname: '',
        forename1: '',
        forename2: '',
        pesel: '',
        birth_date_h: '',
        foreigner: '0',
        nationality: '',
        street: '',
        house_ap_no: '',
        postal_code: '',
        city: '',
        region: '',
        country: '',
        c_street: '',
        c_house_ap_no: '',
        c_postal_code: '',
        c_city: '',
        c_region: '',
        c_country: '',
        bank: '',
        bank_acc_no: '',
      };
    },
  },
  watch: {
    'employee.id': function (newVal) {
      if (newVal !== undefined && newVal !== '') {
        Object.keys(this.employeeForm).forEach(key => { this.employeeForm[key] = this.employee[key] !== undefined ? `${this.employee[key]}` : ''; });
      }
    },
    employeeForm: {
      handler: function() {
        this.emitFormChanged();
      },
      deep: true,
    },
  },
};
</script>
