<template>
  <div id="employee-insurers" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

    <EmployeeInsurersEdit v-if="editModal"
      :employeeInsurers="employeeInsurers"
      :employeeId="employeeId"
      @close="editModal = false"
      @insurersUpdated="insurersUpdated"
    />

    <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">uprawnienia knf</span>
        </div>
      </div>
      <div v-if="validating === 1">
        <button v-if="allowEdit" @click="editModal = true" class="tw--btn-icon pb-0" title="edytuj uprawnienia KNF">
          <FontAwesomeIcon :icon="['far', 'edit']" size="lg" />
        </button>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        błąd modułu<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div id="uprawnienia-knf">
      <div class="flex flex-wrap gap-1 text-xs font-semibold">
        <span v-for="insurer in employeeInsurers" :key="insurer.id" class="px-2 py-1 bg-purple-100 dark:bg-purple-900 rounded-sm">
          {{ insurer.name }}
        </span>
        <span v-if="employeeInsurers.length === 0" class="text-sm text-muted italic">brak</span>
      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';
import EmployeeInsurersEdit from './EmployeeInsurersEdit.vue';

export default {
  name: 'EmployeeInsurers',
  components: { EmployeeInsurersEdit },
  mixins: [errorHandler],
  props: {
    employeeId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      editModal: false,
      employeeInsurers: [],
      validating: 0,
    };
  },
  computed: {
    allowEdit() {
      return this.$store.getters.currentUserType === 'root' || this.$store.getters.currentUserType === 'admin';
    },
  },
  mounted() {
    this.getInsurers();
  },
  methods: {
    getInsurers() {
      EmployeeService.getEmployeeInsurers(this.employeeId)
        .then(response => {
          this.employeeInsurers = response.data.result;
          this.validating = 1;
        })
        .catch(error => {
          this.resolveError(error);
          this.validating = -1;
        });
    },
    insurersUpdated(insurers) {
      this.employeeInsurers = insurers;
      this.editModal = false;
    },
  },
};
</script>