<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      {{ action === 'add' ? 'dodawanie' : 'edycja' }} użytkownika
    </template>
    <template #default>
      <form @submit.prevent="save" class="flex flex-col gap-y-3">
        <div class="flex flex-wrap gap-x-2">
          <BaseInputWithAddon
            class="w-full sm:w-2/5"
            type="text"
            v-model="form.user_name"
            labelTop="login"
            :error="saveErrors.user_name !== undefined ? saveErrors.user_name : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="email"
            v-model="form.email"
            labelTop="email"
            :error="saveErrors.email !== undefined ? saveErrors.email : ''"
          />
        </div>
        <div class="flex flex-wrap gap-x-2">
          <BaseSelectWithAddon
            class="w-full"
            v-model="form.group_id"
            labelTop="poziom uprawnień"
            :options="userGroupList"
            :nullOption="false"
            :error="saveErrors.group_id !== undefined ? saveErrors.group_id : ''"
          />
        </div>
        <div class="flex flex-wrap gap-x-2">
          <p v-show="action === 'add'" class="text-muted italic text-xs">
            * hasło powinien ustawić użytkownik osobiście. Jeśli obecnie nie ma użytkownika przy komputerze,
            pozostaw hasło puste, użytkownik przy pierwszym logowaniu będzie mógł skorzystac z opcji "Nie pamiętam hasła".
          </p>
          <p v-show="action === 'edit'" class="text-muted italic text-xs">
            * pozostaw puste poniższe pola, jeśli nie zamierzasz zmienić hasła. Użytkownicy powinni zmieniac hasła osobiście.
          </p>
          <BaseInputWithAddon
            class="w-full sm:w-1/2"
            type="password"
            v-model="form.password"
            labelTop="nowe hasło"
            :error="saveErrors.password !== undefined ? saveErrors.password : ''"
          />
          <BaseInputWithAddon
            class="w-full sm:w-1/2"
            type="password"
            v-model="form.passconf"
            labelTop="potwierdź nowe hasło"
            :error="saveErrors.passconf !== undefined ? saveErrors.passconf : ''"
          />
        </div>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import UserService from '../../services/UserService';

export default {
  name: 'UserAddEdit',
  mixins: [errorHandler],
  props: {
    user: {
      type: Object,
      required: true,
    },
    personId: {
      type: [Number, String],
      required: true,
    },
    employeeEmail: {
      type: String,
      default: '',
    },
    action: {
      validator: function(value) {
        return ['add', 'edit'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      form: {
        user_name: '',
        email: '',
        group_id: '',
        personal_data_id: '',
        password: '',
        passconf: '',
      },
      userGroupList: [],
      saving: false,
      saveErrors: {},
    };
  },
  mounted() {
    UserService.getUserGroupList()
      .then(response => {
        this.userGroupList = response.data.userGroupsList;
      })
      .catch(error => this.resolveError(error));
    if (this.action === 'edit') {
      this.form.user_name = this.user.user_name;
      this.form.email = this.user.email;
      this.form.group_id = this.user.group_id;
    } else {
      this.form.personal_data_id = this.personId;
      if (this.employeeEmail !== '') {
        this.form.email = this.employeeEmail;
      }
      this.form.group_id = '70';
    }
  },
  methods: {
    save() {
      this.saving = true;
      this.saveErrors = {};
      if (this.action === 'add') {
        UserService.addUser(this.form)
          .then(response => {
            this.saving = false;
            this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
            this.$emit('added', response.data.newUser);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = this.resolveError(error);
          });
      } else if (this.action === 'edit') {
        UserService.updateUser(this.user.id, this.form)
          .then(response => {
            this.saving = false;
            this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
            this.$emit('updated', response.data.updatedUser);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = this.resolveError(error);
          });
      }
    },
    changeStatusHandler(id) {
      if (Number(id) === 40) {
        this.confirm(() => this.changeStatus(id), 'Usunięcie spowoduje nieodwracalne zmiany w bazie. Potwierdasz?');
      } else {
        this.changeStatus(id);
      }
    },
    changeStatus(id) {
      this.form = { status_id: id };
      this.save();
    },
  },
};
</script>
