<template>
  <div id="user-card" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

    <UserAddEdit v-if="addEditModal"
      :action="action"
      :user="user"
      :personId="personId"
      :employeeEmail="employeeEmail"
      @close="addEditModal = false"
      @added="userAdded"
      @updated="userUpdated"
    />

    <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">dane logowania</span>
          <FontAwesomeIcon :icon="userStatus.icon" :class="userStatus.color" size="sm" :title="userStatus.description" />
        </div>
      </div>
      <div v-if="validating === 1">
        <CardMenu>
          <template #default>
            <ul v-if="user.id === undefined" class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="addUser" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['fas', 'plus']" /> dodaj</li>
            </ul>
            <ul v-else class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="editUser" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj</li>
              <li  v-if="Number(user.status_id) < 20" @click="changeStatusHandler(20)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-yellow-400 dark:text-yellow-600" :icon="['fas', 'user-lock']" /> zablokuj
              </li>
              <li v-if="Number(user.status_id) === 20" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-lock']" /> odblokuj
              </li>
              <li v-if="Number(user.status_id) < 30" @click="changeStatusHandler(30)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-red-400 dark:text-red-600" :icon="['fas', 'user-times']" /> wyłącz
              </li>
              <li v-if="Number(user.status_id) === 30" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-times']" /> włącz
              </li>
              <li v-if="Number(user.status_id) !== 40" @click="changeStatusHandler(40)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-red-400 dark:text-red-600" :icon="['fas', 'user-slash']" /> usuń
              </li>
              <li v-if="Number(user.status_id) === 40" @click="changeStatusHandler(1)" class="cursor-pointer hover:underline">
                <FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'user-slash']" /> przywróć
              </li>
            </ul>
          </template>
        </CardMenu>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div id="user-data" v-if="user.id !== undefined">
      <div id="user-details">
        <div class="flex flex-wrap justify-between">
          <div class="flex gap-x-3"><span class="text-muted">login</span>{{ user.user_name }}</div>
          <div class="flex gap-x-3"><span class="text-muted">email <FontAwesomeIcon :icon="['far', 'question-circle']" title="logowanie/reset hasła" /></span><span>{{ user.email }}</span></div>
          <div class="flex gap-x-3"><span class="text-muted">uprawnienia</span>{{ user.group_name }}</div>
        </div>
      </div>
      <div id="user-last-activity" class="my-2">
        <div class="text-xs italic text-muted border-b dark:border-gray-600">
          ostatnia aktywność <a href="#" class="tw--link" @click.prevent="showActivity = !showActivity">{{ showActivity ? 'ukryj' : 'pokaż' }}</a>
        </div>
        <div v-show="showActivity" class="flex flex-col text-sm">
          <div class="flex gap-x-3"><span class="text-muted">ostatnie logowanie</span><span>{{ user.last_cor_login_h }}</span></div>
          <div class="flex gap-x-3"><span class="text-muted">zmiana hasła</span><span>{{ user.change_pass_time_h }}</span></div>
          <div class="flex gap-x-3"><span class="text-muted">konto utworzone</span><span>{{ user.created_time_h }}</span></div>
        </div>
      </div>
    </div>
    <div v-else>
      <span class="text-sm italic">Konto użytkownika nie zostało skonfigurowane - logowanie do aplikacji niemożliwe. Użyj menu kontekstowego, aby dodać nowe konto użytkownika.</span>
    </div>

  </div>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import UserService from '../../services/UserService';
import UserAddEdit from './UserAddEdit.vue';

export default {
  name: 'UserCard',
  components: { UserAddEdit },
  mixins: [confirm, errorHandler],
  props: {
    user: {
      type: Object,
      required: true,
    },
    personId: {
      type: [String, Number],
      required: true,
    },
    employeeEmail: {
      type: String,
      default: '',
    },
    extended: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addEditModal: false,
      action: 'add',
      showActivity: false,
    };
  },
  mounted() {
    this.showActivity = this.extended;
  },
  computed: {
    validating() {// -1 błąd, 0 łąduję dane, 1 ok = MenuCard
      return 1;
    },
    userStatus() {
      if (Number(this.user.status_id) === 40) {
        return { icon: 'user-slash', color: 'text-red-400 dark:text-red-600', description: 'usunięty' };
      } else if (Number(this.user.status_id) === 30) {
        return { icon: 'user-times', color: 'text-red-400 dark:text-red-600', description: 'wyłączony' };
      } else if (Number(this.user.status_id) === 20) {
        return { icon: 'user-lock', color: 'text-yellow-400 dark:text-yellow-600', description: 'zablokowany' };
      } else if (Number(this.user.status_id) === 10) {
        return { icon: 'user-clock', color: 'text-yellow-400 dark:text-yellow-600', description: 'nieaktywny' };
      } else if (Number(this.user.status_id) === 1) {
        return { icon: 'user-check', color: 'text-green-400 dark:text-green-600', description: 'aktywny' };
      }
      return { icon: 'user-slash', color: 'text-gray-400 dark:text-gray-600', description: 'brak konta użytkownika' };
    },
  },
  methods: {
    addUser() {
      this.action = 'add';
      this.addEditModal = true;
    },
    editUser() {
      this.action = 'edit';
      this.addEditModal = true;
    },
    userAdded(user) {
      this.$emit('userAdded', user);
      this.addEditModal = false;
    },
    userUpdated(user) {
      if (user !== undefined) {
        this.$emit('userUpdated', user);
      }
      this.addEditModal = false;
    },
    changeStatusHandler(id) {
      let message = '';
      switch (Number(id)) {
        case 1:
          message = 'Potwierdź ponowną aktywację użytkownika';
          break;
        case 20:
          message = 'Potwierdź zablokowanie użytkownika. Podczas logowania użytkownik otrzyma informację, że konto zostało zablokowane.';
          break;
        case 30:
          message = 'Potwierdź wyłączenie konta użytkownika. Podczas logowania uzytkownik otrzyma informację, że konto nie istnieje w aplikacji.';
          break;
        case 40:
          message = 'Potwierdź usunięcie danych logowania.';
          break;
      }
      this.confirm(() => this.changeStatus(id), message);
    },
    changeStatus(id) {
      UserService.updateUser(this.user.id, { status_id: id})
        .then(response => {
          this.$emit('userUpdated', response.data.updatedUser);
        })
        .catch(error => this.resolveError(error));
    },
  },
};
</script>