<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      {{ action === 'add' ? 'dodawanie' : 'edycja' }} danych do faktury
    </template>
    <template #default>
      <form @submit.prevent="save" class="flex flex-col gap-y-3">
        <BaseInputWithAddon
          type="text"
          v-model="form.name"
          labelTop="pełna nazwa firmy"
          :error="saveErrors.name !== undefined ? saveErrors.name : ''"
        />
        <BaseInputWithAddon
          type="text"
          v-model="form.nip"
          labelTop="nip"
          :error="saveErrors.nip !== undefined ? saveErrors.nip : ''"
        />

        <div id="company-main-address-street" class="flex flex-wrap gap-4 border-t dark:border-gray-600">
          <BaseAutocompleteInput
            class="w-full md:w-2/3 lg:w-full xl:w-2/3"
            v-model="form.street"
            labelTop="ulica"
            :searchUrl="searchStreetUrl"
            :error="saveErrors.street !== undefined ? saveErrors.street : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="text"
            v-model="form.house_ap_no"
            labelTop="nr domu/mieszkania"
            :error="saveErrors.house_ap_no !== undefined ? saveErrors.house_ap_no : ''"
          />
        </div>
        <div id="company-main-address-city" class="flex flex-wrap gap-4">
          <BaseAutocompleteInput
            class="flex-grow"
            @selected="setCity"
            v-model="form.postal_code"
            labelTop="kod pocztowy"
            :searchUrl="searchPostalCodeCityUrl"
            :error="saveErrors.postal_code !== undefined ? saveErrors.postal_code : ''"
          />
          <BaseAutocompleteInput
            class="w-full md:w-2/3 lg:w-full xl:w-2/3"
            v-model="form.city"
            labelTop="miasto"
            :searchUrl="searchCityUrl"
            :error="saveErrors.city !== undefined ? saveErrors.city : ''"
          />
        </div>
        <div id="company-main-address-region-country" class="flex flex-wrap gap-4">
          <BaseAutocompleteInput
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            v-model="form.region"
            labelTop="województwo"
            :searchUrl="searchRegionUrl"
            :error="saveErrors.region !== undefined ? saveErrors.region : ''"
          />
          <BaseAutocompleteInput
            class="flex-grow"
            v-model="form.country"
            labelTop="państwo"
            :searchUrl="searchCountryUrl"
            :error="saveErrors.country !== undefined ? saveErrors.country : ''"
          />
        </div>
        <div id="company-bank" class="flex flex-wrap gap-4 border-t dark:border-gray-600">
          <BaseInputWithAddon
            class="w-full sm:w-1/4"
            type="text"
            v-model="form.bank"
            labelTop="bank"
            :error="saveErrors.bank !== undefined ? saveErrors.bank : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="text"
            v-model="form.bank_acc_no"
            labelTop="nr konta"
            :error="saveErrors.bank_acc_no !== undefined ? saveErrors.bank_acc_no : ''"
          />
        </div>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';

export default {
  name: 'EmployeeCompanyAddEdit',
  mixins: [errorHandler],
  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    action: {
      validator: function(value) {
        return ['add', 'edit'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      form: {
        name: '',
        nip: '',
        street: '',
        house_ap_no: '',
        postal_code: '',
        city: '',
        region: '',
        country: '',
        bank: '',
        bank_acc_no: '',
      },
      searchStreetUrl: 'search-street',
      searchPostalCodeCityUrl: 'search-postal-code-city',
      searchCityUrl: 'search-city',
      searchCountryUrl: 'search-country',
      searchRegionUrl: 'search-region',
      saveErrors: {},
    };
  },
  mounted() {
    if (this.action === 'edit') {
      this.form.name = this.company.name;
      this.form.nip = this.company.nip;
      this.form.street = this.company.street;
      this.form.house_ap_no = this.company.house_ap_no;
      this.form.postal_code = this.company.postal_code;
      this.form.city = this.company.city;
      this.form.region = this.company.region;
      this.form.country = this.company.country;
      this.form.bank = this.company.bank;
      this.form.bank_acc_no = this.company.bank_acc_no;
    }
  },
  methods: {
    setCity(selectedSuggestion) {
      this.form.city = selectedSuggestion.name;
    },
    save() {
      this.saveErrors = {};
      if (this.action === 'edit') {
        this.form.id = this.company.id;
      }
      EmployeeService.updateEmployeeCompany(this.employeeId, this.form)
        .then(response => {
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('companyUpdated');
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
