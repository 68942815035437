<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Edycja pracownika
    </template>
    <template #default>
      <EmployeeForm
        :employee="employeeCache"
        :edit="true"
        :validationErrors="{ errors: saveErrors, warnings: {} }"
        @formChanged="setEmployeeData"
      />
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmployeeService from '../../services/EmployeeService';
import EmployeeForm from '../employee/EmployeeForm.vue';

export default {
  name: 'EmployeeEdit',
  components: { EmployeeForm },
  mixins: [errorHandler],
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeCache: {},
      saveErrors: {},
      saving: false,
    };
  },
  mounted() {
    this.setEmployeeCache();
  },
  methods: {
    setEmployeeCache() {
      this.employeeCache.id = this.employee.id;
      this.employeeCache.empl_type_id = this.employee.empl_type_id;
      this.employeeCache.empl_form_id = this.employee.empl_form_id;
      this.employeeCache.is_manager = this.employee.is_manager;
      this.employeeCache.manager_id = this.employee.manager_id;
      this.employeeCache.provision_lvl = this.employee.provision_lvl;
      this.employeeCache.surname = this.employee.person.surname ? this.employee.person.surname : '';
      this.employeeCache.forename1 = this.employee.person.forename1 ? this.employee.person.forename1 : '';
      this.employeeCache.forename2 = this.employee.person.forename2 ? this.employee.person.forename2 : '';
      this.employeeCache.pesel = this.employee.person.pesel ? this.employee.person.pesel : '';
      this.employeeCache.birth_date_h = this.employee.person.birth_date_h ? this.employee.person.birth_date_h : '';
      this.employeeCache.foreigner = this.employee.person.foreigner ? this.employee.person.foreigner : '';
      this.employeeCache.nationality = this.employee.person.nationality ? this.employee.person.nationality : '';
      this.employeeCache.street = this.employee.person.street;
      this.employeeCache.house_ap_no = this.employee.person.house_ap_no;
      this.employeeCache.postal_code = this.employee.person.postal_code;
      this.employeeCache.city = this.employee.person.city;
      this.employeeCache.region = this.employee.person.region;
      this.employeeCache.country = this.employee.person.country;
      this.employeeCache.c_street = this.employee.person.c_street;
      this.employeeCache.c_house_ap_no = this.employee.person.c_house_ap_no;
      this.employeeCache.c_postal_code = this.employee.person.c_postal_code;
      this.employeeCache.c_city = this.employee.person.c_city;
      this.employeeCache.c_region = this.employee.person.c_region;
      this.employeeCache.c_country = this.employee.person.c_country;
      this.employeeCache.bank = this.employee.person.bank;
      this.employeeCache.bank_acc_no = this.employee.person.bank_acc_no;
    },
    setEmployeeData(customerForm) {
      Object.assign(this.employeeCache, customerForm);
    },
    save() {
      this.saving = true;
      EmployeeService.updateEmployee(this.employee.id, this.employeeCache)
        .then(() => {
          this.saveErrors = {};
          this.$emit('employeeUpdated');
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Dane pracownika zostały zmodyfikowane'});
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
        });
    },
  },
};
</script>
